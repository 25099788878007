const myTheme = {
  colors: {
    background: "#f7f7f7",
    darkblue: "#4A5875",
    green: "#00b59c",
    red: "#EB5D5D",
    white: "#fff",
  },
}

export default myTheme
