import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "./container"
import { ReactComponent as Cross } from "../assets/img/icons/cross.svg"

// Url params are not available
const ErrorText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkblue};
`

const Icon = styled.div`
  svg path {
    fill: ${({ theme }) => theme.colors.red};
  }
`

const Error = ({ children, id }) => (
  <Container>
    <Icon>
      <Cross />
    </Icon>
    <ErrorText data-testid={id}>{children}</ErrorText>
  </Container>
)

Error.displayName = "Error"
Error.defaultProps = {
  id: "approval-error",
  children: "This is an error",
}

Error.propTypes = {
  /** Used as Id and data-testid */
  id: PropTypes.string,
  /** React node to be rendered within Button */
  children: PropTypes.node,
}

export default Error
