import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { build } from "./metadata.json"
import {
  postApprovalToken,
  getTravelFromToken,
} from "./services/approvals.rest"
import formattingError from "./errors/errors.utils"
import Loader from "./components/loader"
import Error from "./components/error"
import Success from "./components/success"
import Logo from "./assets/img/logo-dark.png"

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`

const HeaderVersion = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
`

const HeaderText = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.darkblue};
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 2em;
  min-width: 300px;
`

const Img = styled.img`
  width: 100%;
  max-width: 200px;
  margin: auto;
`

const App = ({ configuration, action, token }) => {
  // App status
  const [debug] = useState({
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    build,
  })

  // Request POST approval status
  const [postStatus, setPostStatus] = useState({
    loading: false,
    status: "pending",
    error: null,
  })

  // Request GET travel status
  const [getStatus, setGetStatus] = useState({
    loading: false,
    status: "pending",
    error: null,
  })

  // Prevent user to quit the page during process
  /* istanbul ignore next */
  window.onbeforeunload = (event) => {
    if (postStatus.loading) {
      event.preventDefault() // standard method to trigger prompt
      return event.returnValue // compat
    }
    return null
  }

  // Approve or deny travel
  useEffect(() => {
    // Request to approve or deny travel
    const approveOrDenyTravel = async () => {
      try {
        setPostStatus({ loading: true, status: "inprogress", error: null })
        await postApprovalToken(configuration, token)
        setPostStatus({ loading: false, status: "completed", error: null })
      } catch (error) {
        setPostStatus({
          loading: false,
          status: "failed",
          error: formattingError(
            error,
            `POST ${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`
          ),
        })
      }
    }
    approveOrDenyTravel()
  }, [configuration, token])

  // Start loading travel informations if approval process is not completed after 5000ms
  useEffect(() => {
    const getTravelToApprove = async () => {
      try {
        setGetStatus({ loading: true, status: "inprogress", error: null })
        await getTravelFromToken(configuration, token)
        setGetStatus({ loading: false, status: "completed", error: null })
      } catch (error) {
        setGetStatus({
          loading: false,
          status: "failed",
          error: formattingError(
            error,
            `GET ${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`
          ),
        })
      }
    }
    getTravelToApprove()
  }, [configuration, token])

  return (
    <Wrapper data-testid="app">
      <Header data-testid="header">
        <HeaderVersion data-testid="header-debug">
          <HeaderText data-testid="app-name">{`${debug.name} - `}</HeaderText>
          <HeaderText data-testid="app-version">{`Version ${debug.version} - `}</HeaderText>
          <HeaderText data-testid="app-build">{`Build ${debug.build}`}</HeaderText>
        </HeaderVersion>
        <Img src={Logo} alt="The Treep logo" />
      </Header>
      <Card>
        {postStatus.loading && <Loader data-testid="spinner" />}
        {action === "approve" && postStatus.status === "completed" && (
          <Success id="approval-success">
            {window.i18(
              navigator.language.split("-")[0],
              "TRAVEL_APPROVED_MESSAGE"
            )}
          </Success>
        )}
        {action === "deny" && postStatus.status === "completed" && (
          <Success id="approval-success">
            {window.i18(
              navigator.language.split("-")[0],
              "TRAVEL_DENIED_MESSAGE"
            )}
          </Success>
        )}
        {postStatus.error && (
          <Error id="approval-error">
            <div>{postStatus.error.title}</div>
            <div>{postStatus.error.message}</div>
          </Error>
        )}
        {getStatus.loading && <Loader data-testid="spinner" />}
      </Card>
    </Wrapper>
  )
}

App.displayName = "App"
App.propTypes = {
  action: PropTypes.oneOf(["approve", "deny"]).isRequired,
  token: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    endpoint: PropTypes.string.isRequired,
    services: PropTypes.shape({
      approval: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default App
