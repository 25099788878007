import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "./container"
import { ReactComponent as Check } from "../assets/img/icons/check.svg"

// Travel had been denied or approved with success
const StyledSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkblue};
`

const SuccessRemaining = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: colum;
  color: ${({ theme }) => theme.colors.darkblue};
  font-weight: 200;
`

const Icon = styled.div`
  svg path {
    fill: ${({ theme }) => theme.colors.green};
  }
`

const Success = ({ children, id }) => {
  const [remaining, setRemaining] = useState(7)

  useEffect(() => {
    // Refresh time remaining before closing
    const interval = setInterval(() => {
      // eslint-disable-next-line no-shadow
      setRemaining((remaining) => remaining - 1)
    }, 1000)

    // Closing after 5000ms
    setTimeout(() => {
      window.close()
    }, 7000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container>
      <Icon>
        <Check />
      </Icon>
      <StyledSuccess data-testid={id}>
        {children}
        <SuccessRemaining data-testid="remaining-success">
          {`${window.i18(
            navigator.language.split("-")[0],
            "PAGE_WILL_CLOSE"
          )} ${remaining}`}
        </SuccessRemaining>
      </StyledSuccess>
    </Container>
  )
}

Success.displayName = "Success"
Success.defaultProps = {
  id: "alert",
  children: "This is an alert",
}

Success.propTypes = {
  /** Used as Id and data-testid */
  id: PropTypes.string,
  /** React node to be rendered within Button */
  children: PropTypes.node,
}

export default Success
