import axios from "axios"

const postApprovalToken = (configuration, token) =>
  axios.post(
    `${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`,
    {},
    {
      headers: {
        "api-key": configuration.headers.api_key,
        "X-Product": configuration.headers.x_product,
        "X-Client": configuration.headers.x_client,
      },
    }
  )

const getTravelFromToken = (configuration, token) =>
  axios.get(
    `${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`,
    {},
    {
      headers: {
        "api-key": configuration.headers.api_key,
        "X-Product": configuration.headers.x_product,
        "X-Client": configuration.headers.x_client,
      },
    }
  )

export { postApprovalToken, getTravelFromToken }
